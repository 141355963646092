import Vue from "vue";
var vm = new Vue();

import Router from "vue-router";
//import { VMain } from "vuetify/lib";

import store from "@/core/services/store";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "route.home",
      // redirect: "/dashboard",
      redirect: () => {
        return store.getters.getDevice === "mobile"
          ? {
              name: "route.mobile.operations.list",
              params: { limit: 9999, Status: "M", runCallBySearch: true },
            }
          : "/dashboard";
      },
      component: () => import("@/view/layout/Layout"),
      // only authenticated users can
      meta: { requiresAuth: true },
      children: [
        {
          path: "/dashboard",
          name: "route.dashboard",
          // redirect: () => {
          //   return store.getters.getDevice === "mobile"
          //     ? {
          //         name: "route.mobile.operations.list",
          //         params: { limit: 9999, Status: "M", runCallBySearch: true },
          //       }
          //     : {};
          // },
          component: () => {
            if (store.getters.getDevice === "mobile") return import("@/view/pages/mobile/Dashboard.vue");
            // Not needed anymore
            else return import("@/view/pages/Dashboard.vue");
          },
          // only authenticated users can
          meta: { requiresAuth: true },
        },
        {
          path: "/operations",
          name: "route.operations",
          redirect: {
            name: "route.operations.list",
          },
          component: () => import("@/view/pages/Operations.vue"),
          children: [
            {
              path: "/operations/create/:OperationTID?",
              name: "route.operations.create",
              component: () => import("@/view/pages/operations/EditOperation.vue"),
              // only authenticated users can
              meta: { requiresAuth: true },
            },
            {
              path: "/operations/edit/:OperationUID?",
              name: "route.operations.edit",
              component: () => import("@/view/pages/operations/EditOperation.vue"),
              // only authenticated users can
              meta: { requiresAuth: true },
            },
            {
              path: "/operations/list/:PartnerID?/:PartnerName?/:Status?/:runCallBySearch?",
              name: "route.operations.list",
              component: () => import("@/view/pages/operations/ListOperations.vue"),
              // only authenticated users can
              meta: { requiresAuth: true },
            },
            {
              path: "/operations/import",
              name: "route.operations.import",
              component: () => import("@/view/pages/operations/ImportOperation.vue"),
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "/accounts/:PartnerName?/:PalletName?/:GroupBy?",
          name: "route.accounts",
          component: () => import("@/view/pages/accounts/ListAccounts.vue"),
          // only authenticated users can
          meta: { requiresAuth: true },
        },
        {
          path: "/stocks",
          name: "route.stocks",
          component: () => import("@/view/pages/stocks/ListStocks.vue"),
          // only authenticated users can
          meta: { requiresAuth: true },
        },
        {
          path: "/partners",
          name: "route.partners",
          redirect: {
            name: "route.partners.list",
          },
          component: () => import("@/view/pages/Partners.vue"),
          // only authenticated users can
          meta: { requiresAuth: true },

          children: [
            {
              path: "/partners/create",
              name: "route.partners.create",
              component: () => import("@/view/pages/partners/EditPartner.vue"),
              // only authenticated users can
              meta: { requiresAuth: true },
            },
            {
              path: "/partners/edit/:PartnerID?/:GlobalID?",
              name: "route.partners.edit",
              component: () => import("@/view/pages/partners/EditPartner.vue"),
              props: true,
              // only authenticated users can
              meta: { requiresAuth: true },
            },
            {
              path: "/partners/list",
              name: "route.partners.list",
              component: () => import("@/view/pages/partners/ListPartners.vue"),
              // only authenticated users can
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "/reports",
          name: "route.reports",
          component: () => import("@/view/pages/Reports.vue"),
          // only authenticated users can
          meta: { requiresAuth: true },
        },
        {
          path: "/map",
          name: "route.map",
          component: () => import("@/view/pages/Map.vue"),
          // only authenticated users can
          meta: { requiresAuth: true },
        },
        // Mobile
        {
          path: "/mobile/dashboard",
          name: "route.mobile.dashboard",
          component: () => import("@/view/pages/mobile/Dashboard.vue"),
          // only authenticated users can
          meta: { requiresAuth: true },
        },
        {
          path: "/mobile/operations/list/:Status?/",
          name: "route.mobile.operations.list",
          component: () => import("@/view/pages/mobile/ListOperations.vue"),
          // only authenticated users can
          meta: { requiresAuth: true },
        },
        {
          path: "/mobile/scan",
          name: "route.mobile.scan",
          component: () => import("@/view/pages/mobile/Scan.vue"),
          // only authenticated users can
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/view/pages/auth/Login"),
      // anybody can
      meta: { requiresAuth: false },
      // children: [
      //   {
      //     name: "login",
      //     path: "/login",
      //     component: () => import("@/view/pages/auth/Login"),
      //   },
      //   /*{
      //     name:  "register",
      //     path: "/register",
      //     component: () => import("@/view/pages/auth/Login")
      //   }*/
      // ],
    },
    {
      name: "autologin",
      path: "/autologin",
      component: () => import("@/view/pages/auth/AutoLogin.vue"),
      // anybody can
      meta: { requiresAuth: true },
    },
    {
      name: "disconnected",
      path: "/disconnected",
      component: () => import("@/view/pages/auth/Disconnected.vue"),
      // anybody can
      meta: { requiresAuth: false },
    },
    {
      path: "*",
      redirect: "/404",
      // anybody can
      meta: { requiresAuth: false },
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error.vue"),
      // anybody can
      meta: { requiresAuth: false },
    },
  ],
});
